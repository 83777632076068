import React from 'react';
import {
  PhotoCameraOutlined,
  SentimentSatisfiedRounded,
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import 'emoji-mart/css/emoji-mart.css';
import Emojies from 'emoji-mart/data/apple.json';
import { Picker } from 'emoji-mart';
import { addSupportChat, addChat, addAdminChat } from '@/actions';

import styles from './styles';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({
  type,
  id,
  notify,
  setChat,
  parseData,
  user,
  mode,
  orderStatus,
}) => {
  const [state, setState] = React.useState({
    msg: '',
    loading: false,
    emoji: false,
    file: null,
  });

  const cls = styles({ emoji: state.emoji });

  if (type === 'adminNewChat') {
    var fun = addAdminChat;
  } else if (type === 'support') {
    var fun = addSupportChat;
  } else {
    var fun = addChat;
  }

  React.useEffect(() => {
    if (state.file) {
      setState({ loading: true });
      fun(id, state.file)
        .then(() => setState((st) => ({ ...st, file: null, loading: false })))
        .catch((err) => {
          notify(err, 'warning');
          setState((st) => ({ ...st, file: null, loading: false }));
        });
    }
  }, [state.file, notify, id]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      setState({ loading: true });
      setChat(({ DATA = [], otherPart, user = {} }) => ({
        otherPart,
        user,
        DATA: DATA.concat(
          parseData(
            [
              {
                message: e.target.value,
                mode: mode || '',
                humans_time: 'Now',
                me: user.id,
                sender: {
                  name: user.name,
                  image: user.image,
                },
                user_id: user.id,
              },
            ],
            user
          )
        ),
      }));
      fun(id, state.msg, (mode = ''))
        .then(() => {
          setState((st) => ({ ...st, msg: '', loading: false }));

          // Scroll to the end of the list
          let myList = document.getElementById('chatslist');
          if (myList) {
            myList.scrollTop = myList.scrollHeight;
          }
          // Optional: Uncomment if needed
          // SaveData({ refresh: true });
          // SaveData({ refreshChat: true });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const handleSendMsg = (e) => {
    if (state.msg) {
      setState({ loading: true });
      setChat(({ DATA = [], otherPart, user = {} }) => ({
        otherPart,
        user,
        DATA: DATA.concat(
          parseData(
            [
              {
                message: state.msg,
                mode: mode || '',
                humans_time: 'Now',
                me: user.id,
                sender: {
                  name: user.name,
                  image: user.image,
                },
                user_id: user.id,
              },
            ],
            user
          )
        ),
      }));
      fun(id, state.msg, (mode = ''))
        .then(() => {
          setState((st) => ({ ...st, msg: '', loading: false }));

          // Scroll to the end of the list
          let myList = document.getElementById('chatslist');
          if (myList) {
            myList.scrollTop = myList.scrollHeight;
          }
          // Optional: Uncomment if needed
          // SaveData({ refresh: true });
          // SaveData({ refreshChat: true });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  return (
    <>
      <div className={cls.container}>
        {/* <div className={cls.chat}>
        <Picker
          set="apple"
          data={Emojies}
          showPreview={false}
          showSkinTones={false}
          emojiSize={20}
          perLine={7}
          title="Emoji"
          sheetSize={32}
          include={['people', 'smileys', 'flags', 'activity', 'symbols']}
          onSelect={({ native }) =>
            setState({ ...state, msg: state.msg + native })
          }
          native
        />
      </div> */}

        {/* <SentimentSatisfiedRounded
        className={cls.emoji}
        onClick={() => setState({ ...state, emoji: !state.emoji })}
      /> */}
        {state.loading ? (
          <CircularProgress size={20} />
        ) : (
          <input
            disabled={orderStatus.value < 2 ? true : false}
            autoFocus
            className={cls.input}
            placeholder="Type your message here ..."
            // value={state.msg}
            onChange={(e) => setState({ ...state, msg: e.target.value })}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        )}
        <label className={cls.camera}>
          <input
            disabled={orderStatus.value < 2}
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e) => {
              if (e.target.files.length > 0) {
                const file = e.target.files[0];

                // Example: Generate a temporary URL for preview (or replace with actual upload link)
                const fileURL = URL.createObjectURL(file);

                setState({ ...state, file });

                // Update chat with the file link
                setChat(({ DATA = [], otherPart, user = {} }) => ({
                  otherPart,
                  user,
                  DATA: DATA.concat(
                    parseData(
                      [
                        {
                          message: fileURL, // Add the file URL instead of a message
                          mode: mode || '',
                          humans_time: 'Now',
                          me: user.id,
                          sender: {
                            name: user.name,
                            image: user.image,
                          },
                          user_id: user.id,
                        },
                      ],
                      user
                    )
                  ),
                }));
              }
            }}
          />
          <ReactSVG src={Icons.fileSelect} className={cls.emptyChatIcon} />
        </label>
      </div>
      {orderStatus.value < 2 ? (
        <span className={cls.sendMessageBtn}>
          <ReactSVG src={Icons.sendMessage} className={cls.emptyChatIcon} />
        </span>
      ) : (
        <span
          onClick={(e) => handleSendMsg(e)}
          className={cls.sendMessageActiveBtn}>
          <ReactSVG
            src={Icons.sendMessageBlack}
            className={cls.emptyChatIconActive}
          />
        </span>
      )}
    </>
  );
};
