import React, { useState, useRef, useEffect } from 'react';
import styles from './styles';
import { useOutsideAlerter } from '@/hocs';

export default function StatusFilter({
  display,
  setDisplay,
  setFilter,
  options,
  selectedFilters,
}) {
  const [selectedStatuses, setSelectedStatuses] = useState(
    selectedFilters || []
  );
  const cls = styles();
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setDisplay('none'));

  const toggleStatus = (status) => {
    setSelectedStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const resetFilters = () => {
    setSelectedStatuses([]);
    setFilter([]);
    setDisplay('none');
  };

  useEffect(() => {
    setSelectedStatuses(selectedFilters);
  }, [selectedFilters]);

  return (
    <div ref={wrapperRef} className={cls.dropdownContainer} style={{ display }}>
      <div className={cls.dropdownBody}>
        {options.map(({ label, value }) => (
          <label key={value} className={cls.checkboxContainer}>
            <input
              type="checkbox"
              checked={selectedStatuses.includes(value)}
              onChange={() => toggleStatus(value)}
              className={cls.customCheckbox}
            />
            <span className={cls.labelText}>{label}</span>
          </label>
        ))}
        <div className={cls.buttonContainer}>
          <button onClick={resetFilters} className={cls.resetButton}>
            Reset
          </button>
          <button
            onClick={() => {
              setFilter(selectedStatuses);
              setDisplay('none');
            }}
            className={cls.applyButton}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
