import React, { useEffect, useState, useRef } from 'react';
import { Loading } from 'react-admin';
import { Button, TextField, Tooltip, Grid } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';

import { locationOpt, _get } from '@/actions/Helper';
import DateInput from '@/components/DateInput';

import styles from './setAppointmentStyle';
import { availableApointmentTimes, updateInventoriContainers } from '@/actions';
import Containers from '../AIGroup/Containers';
import { size } from 'lodash';
import { TerminalsIcon } from '@/assets';

export default ({
  Update,
  CloseAppointmentSet,
  dataSet,
  containerId,
  container,
  shift,
  terminal,
  terminalId,
  dat,
}) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const Sizes = useSelector((state) => state.reducer.size);
  const SSLs = useSelector((state) => state.reducer.ssl);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [Data, setData] = React.useState([]);
  const parseData = ({ id, size, ssl, date, count_all, address }) => ({
    id: containerId,
    date,
    count: count_all,
    size: Sizes.find(({ label }) => label === size),
    ssl: SSLs.find(({ label }) => label === ssl),
    loc: {
      '& svg': {
        fill: '#f8b100',
      },

      label: _get(address, 'address', ''),
      value: {
        lng: _get(address, 'long', ''),
        lat: _get(address, 'lat', ''),
        address: _get(address, 'address', ''),
        city: _get(address, 'city', ''),
      },
    },
    container_name: container.name,
    chasses_name: container.chases,
  });
  const todayy = new Date();
  const formattedSelectedDate = formatDate(selectedDate);

  function getCurrentWeekDates(daydate) {
    const today = new Date();

    // Adjust for Monday-based weeks: getDay() returns 0 for Sunday, 1 for Monday, etc.
    const currentDay = (daydate.getDay() + 6) % 7; // Adjust so Monday is 0 and Sunday is 6

    const firstDayOfWeek = new Date(daydate);
    firstDayOfWeek.setDate(daydate.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  useEffect(() => {
    getData();
  }, []);

  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format == 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayNumber, dayName, monthNumber, month, year };
    }
  }
  const weekDates = getCurrentWeekDates(selectedDate);
  const formattedDates = weekDates.map((date) => formatDate(date));

  const [containers, setContainers] = React.useState(dataSet?.containers);

  const [form, setFormData] = React.useState({
    id: null,
    gated_in: container.gated_in,
    gated_out: container.gated_out,
    container_name: container.name,
    chasses_name: container.chases,
    size: null,
    ssl: null,
    date: null,
    count: 0,
    loc: null,
  });

  React.useEffect(() => {
    setFormData(parseData(dataSet));
  }, [dataSet, Sizes, SSLs]);

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const handleUpdateContainers = (name, value, index) => {
    setContainers((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const submit = () => {
    // setLoading(true);
    CloseAppointmentSet();
    // Update(form, containerId, containers).then(() => setLoading(false));
  };
  const getData = () => {
    availableApointmentTimes({
      specificationId: form?.size,
      terminalId: terminalId,
      date: dat,
    }).then((res) => {
      setData(res.availabilities);
    });
  };

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  // const getInventoryContainers = () => {
  //   let inventoryContainers = [];
  //   dataSet.containers.forEach((container) => {
  //     console.log('con', container);
  //     console.log('ff', form);

  //     // if (container.status) {
  //     inventoryContainers.push({
  //       size: dataSet.size,
  //       ssl: dataSet.ssl,
  //       location: dataSet.loc,
  //       lfd: formatDateTimeToUTC(container.last_free_day),
  //       aptType: 'None',
  //       chassesType:
  //         container.own_chases === 0
  //           ? 'Pool'
  //           : container.own_chases === 1
  //           ? 'Own'
  //           : '',
  //       terminalApt: container.return_appointment || 'No Apt.',
  //       name: container.name ? container.name : '',
  //       id: container.id ? container.id : null,
  //       chases: container.chases ? container.chases : '',
  //       status: container.status ? container.status : null,
  //       gated_in: container.gated_in
  //         ? formatDateTimeToUTC(container.gated_in)
  //         : null,
  //       gated_out: container.gated_out
  //         ? formatDateTimeToUTC(container.gated_out)
  //         : null,
  //     });
  //     // }
  //   });
  //   setContainers(inventoryContainers);
  // };

  // useEffect(() => {
  //   getInventoryContainers();
  // }, []);

  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal' && CloseAppointmentSet()}
      className={cls.popup}>
      <div className={cls.card}>
        {loading ? (
          <Loading className={cls.loading} />
        ) : (
          <>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded
                className={cls.closeP}
                onClick={CloseAppointmentSet}
              />
            </Tooltip>
            <table style={{ marginBottom: '30px' }} className={cls.list}>
              <thead>
                <tr className={cls.thead}>
                  <th style={{ flex: 1 }}>Terminal</th>
                  <th style={{ flex: 1 }}>Date</th>
                  <th style={{ flex: 1 }}>Shift</th>
                </tr>
              </thead>
              <tbody className={cls.tbody}>
                <>
                  {container.id === containerId ? (
                    <tr
                      style={{ border: '1px solid #e9e9e9' }}
                      className={cls.tableRow}>
                      <td style={{ flex: 1 }}>{terminal}</td>
                      <td style={{ flex: 1 }}>{dat}</td>
                      <td style={{ flex: 1 }}>{shift}</td>
                    </tr>
                  ) : (
                    ''
                  )}
                </>
              </tbody>
            </table>
            <div className={cls.timesDiv}>
              <span className={cls.span1Header}>
                <span className={cls.span2Header}>Time Slot</span>
                <span className={cls.span2Header}>Available Appointments</span>
              </span>
              {Data.map((data) => (
                <span className={cls.span1}>
                  <span className={cls.span2}>{data?.raw_data?.date}</span>
                  <span className={cls.span2}>
                    {data?.raw_data?.appointmentsCount}
                  </span>
                </span>
              ))}
            </div>
            <Button className={cls.but} onClick={submit}>
              Make Booking
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
