import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    // padding: '10px 0',
    // boxSizing: 'border-box',
    // width: 360,
    height: '100%',
    // boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    // backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // borderRadius: 10,
    // position: 'absolute',
    // right: '0',
    zIndex: 10,
    // top: 0,
    backgroundSize: 'cover',
    backgroundImage: 'url(/static/media/wpback.a2e62ace.jpg)',

    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  inputContainer: {
    // position: 'absolute',
    width: '100%',
    bottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    height: 40,
    background: '#fff',
    // '& > div:first-child': {
    //   width: '60% !important',
    //   backgroundColor: '#0074f8 !important',
    // },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '15px',
    // marginBottom: 10,
    height: '65%',
  },
  bigContent: {
    height: '100% !important',
    // marginBottom: '8px',
    padding: '0 4px 8px 6px',
    backgroundColor: 'rgb(255 255 255 / 60%)',
  },
  loading: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateSeparator: {
    backgroundColor: '#F3F4F6',
    color: '#4B5563',
    padding: '4px 16px',
    borderRadius: '9999px',
    fontSize: '10px',
    fontWeight: '500',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginTop: 10,
  },
  line: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: '#D1D5DB',
  },
}));

export default useStyles;
