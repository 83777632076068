import React from 'react';
import { Avatar } from '@material-ui/core';
import styles from './styles';

export default ({ DATA = {}, popUp, userone, usertwo, chatType }) => {
  // console.log('az chat', DATA, 'userone', userone, 'usertwo', usertwo);

  const cls = styles({ me: DATA.me });
  const image =
    DATA.message.startsWith('blob:') ||
    DATA.message.includes('https://smart-turn.com/storage/message') ||
    DATA.message.includes('https://apitest.smart-turn.com/storage/message') ||
    DATA.message.includes('https://testapi.smart-turn.com/storage/message') ||
    DATA.message.includes('https://api.smart-turn.com/storage/message');
  return DATA?.message_type === '1' ||
    DATA?.message_type === '2' ||
    DATA?.message_type === '3' ? null : (
    <div className={cls.card}>
      <div className={cls.chat}>
        {/* {DATA.me ? (
          ''
        ) : (
          <span className={cls.chatInfo}>
            <span className={cls.chatUserInfo}>
              <Avatar className={cls.avatar} src={DATA.avatar} alt={DATA.name} />
              <span>{usertwo?.company}</span>
            </span>
          </span>
        )} */}
        {image ? (
          <img
            className={cls.img}
            src={DATA.message}
            alt="Chat"
            onClick={() =>
              // popUp(DATA)
              window.open(DATA.message, '_blank')
            }
          />
        ) : (
          <div className={cls.title}>
            {DATA.message.includes(',')
              ? DATA.message
                  .split(',')
                  .map((item, index) => (
                    <span className={cls.chatLine}>{item}</span>
                  ))
              : DATA.message}
          </div>
        )}
        <div className={cls.time}>{DATA.time}</div>
      </div>
    </div>
  );
};
