import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  CountdownTimer: {
    display: 'flex',
    fontSize: 16,
    padding: '4px 12px',
    fontWeight: 'bold',
    background: '#FEF7E8',
    color: '#FFAD00',
    borderRadius: 50,
    width: 60,
    margin: '0px 5px',
  },
});
