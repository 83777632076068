import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 20px',
  },
  card: {
    // height: 192,
    flexDirection: 'column',
    borderRadius: 5,
    width: '100%',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#303030',
    margin: '10px 0',
    padding: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    '@media (max-width:1280px)': {
      flexDirection: 'column',
      // height: 210,
    },
    '@media (max-width:960px)': {
      height: 'fit-content',
    },
    '@media (max-width:450px)': {
      padding: '20px',
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    boxSizing: 'border-box',
    '@media (max-width:1280px)': {
      flex: '1',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '& a': { textDecoration: 'none' },
    '@media (max-width:900px)': {
      flexDirection: 'column',
    },
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  select: {
    fontFamily: 'Averta Light',
    minWidth: 160,
    flex: '1',
    '& div': {
      borderWidth: 0,
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '@media (max-width:900px)': {
      marginLeft: '0 !important',
    },
  },
  date: {
    boxSizing: 'border-box',
    minWidth: 160,
    width: '100%',
    flex: '1',
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    fontSize: 12,
    '& input': {
      paddingLeft: 10,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
      padding: 8,
    },
    '& button': {
      padding: 8,
    },
    '@media (max-width:900px)': {
      marginLeft: '0 !important',
    },
  },
  ml10: {
    marginLeft: 10,
  },
  ml30: {
    marginLeft: 30,
  },
  input: {
    fontFamily: 'Averta Light',
    minWidth: 160,
    minHeight: 38,
    width: '100%',
    height: '100%',
    flex: '1',
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
    '@media (max-width:900px)': {
      marginLeft: '0 !important',
    },
  },
  bigInput: {},
  text: {
    fontFamily: 'Averta Light',
    flex: '1',
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1.25,
    color: '#fff',
    whiteSpace: 'nowrap',
    marginBottom: 0,
    '@media (max-width:900px)': {
      fontSize: '13px !important',
      // paddingBottom: 10,
    },
    '@media (max-width:600px)': {
      fontSize: '10px !important',
    },
  },
  responsivePadding: {
    padding: 12,
    '@media (max-width:1280px)': {
      padding: 10,
    },
  },
  hideInMobile: {
    '@media (max-width:960px)': {
      display: 'none',
    },
  },
  find: {
    flex: '1',
    width: '200px',
    height: 40.3,
    borderRadius: '0 5px 5px 0',
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Bold',
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: 1.86,
    color: '#303030',
    padding: 0,
    '&:hover': {
      backgroundColor: '#ffd616',
    },
    '@media (max-width:1280px)': {
      marginTop: 0,
    },
    '@media (max-width:960px)': {
      width: '100%',
    },
    '@media (max-width:600px)': {
      width: '100%',
      marginTop: 0,
      marginLeft: 0,
    },
  },
  findIcon: {
    width: 28,
    height: 40,
    fill: '#303030',
  },
  rowRound: {
    overflow: 'hidden',
    width: '100%',
    minWidth: 160,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    cursor: 'pointer',
    '@media (max-width:450px)': {
      marginLeft: 0,
      marginTop: 5,
      width: '100%',
    },
  },
  switch: {
    '@media (max-width:960px)': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  active: {
    borderRadius: 5,
    backgroundColor: '#f8b100',
    color: '#000',
    animation: (props) =>
      props.prefrence ? 'slideInLeft 0.5s' : 'slideInRight 0.5s',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
  text2: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease-in',
    fontSize: '12px',
    '&:hover': {
      background: '#edecec',
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    paddingLeft: 16,
    '@media (max-width:1280px)': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 0,
      paddingTop: 16,
      width: '100%',
    },
    '@media (max-width:900px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
    '@media (max-width:600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  prefrence: {
    width: '100%',
    height: '100%',
    display: 'flex',
    gap: 6,
    '@media (max-width:900px)': {
      marginLeft: '0 !important',
    },
    '@media (max-width:450px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  chassisText: {
    textAlign: 'center',
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 5,
    flex: '1',
    '&:hover': {
      background: '#edecec',
    },
    '&:first-child': {
      borderRight: '2px solid #000000',
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  notifContainer: {
    display: 'flex',
    width: '100%',
    minWidth: 160,
  },
  searchActionContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 15,
    gap: 15,
  },
  notifList: {
    borderRadius: '5px 0 0 5px',
    backgroundColor: '#f8b100 !important',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5px',
    cursor: 'pointer',
    height: 40.3,
    borderRight: '1px solid #000000',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
  notificationText: {
    flex: '1',
    height: 29,
    borderRight: '2px solid #000000',
    minWidth: 90,
    textAlign: 'center',
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 5,
    flex: '1',
    borderRadius: '5px 0 0 5px ',
    cursor: 'pointer',
    background: '#ffffff',
    '&:hover': {
      background: '#edecec',
    },
  },
  activeChassis: {
    backgroundColor: '#f8b100 !important',
    color: '#000 !important',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'cneter',
    justifyContent: 'space-between',
  },
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    // zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999999999999999999999',
  },

  // popupCard: {
  //   position: 'relative',
  //   padding: '25px 40px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'space-evenly',
  //   borderRadius: 5,
  //   boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
  //   backgroundColor: '#fff',
  //   boxSizing: 'border-box',
  //   maxHeight: '75%',
  //   maxWidth: '80%',
  //   animation: 'zoomIn 0.7s',
  //   height: 'auto',
  // },

  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },

  icon: {
    width: 28,
    height: 28,
    fill: '#f8b100',
    marginRight: 15,
  },
  tableContainer: {
    display: 'flex',
    overflow: 'auto',
  },
  searchesTable: {
    width: '750px',
    minWidth: '750px',
    marginBottom: 20,
    '& tr': {
      display: 'flex',
    },
    '& th': {
      whiteSpace: 'nowrap',
      color: '#777575',
      fontSize: '13px',
      padding: '10px',
    },
    '& td': {
      whiteSpace: 'nowrap',
      padding: '0 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000000',
      fontSize: '13px',
      height: '27px',
      borderBottom: '1px solid #dedede',
      '& label': {
        margin: 0,
      },
      '& svg': {
        fill: '#000000',
      },
    },
  },
  doted: {
    overflow: 'hidden',
    textOverflow: 'ellips',
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b !important',
  },
  closeBtn: {
    background: '#3c845d',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '15px',
    display: 'block',
    width: 'fit-content',
    marginLeft: 'auto',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    '@media (max-width:1280px)': {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 0,
      width: '49%',
      // height: 210,
    },
    '@media (max-width:900px)': {
      marginLeft: 0,
      width: '100%',
    },
    '@media (max-width:600px)': {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'normal',
    },
  },
  searchActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: 15,
    '& button': {
      borderRadius: '5px',
      backgroundColor: '#f8b100',
      fontFamily: 'Averta Bold',
      fontSize: 21,
      fontWeight: 'bold',
      lineHeight: 1.86,
      color: '#303030',
      padding: '0 15px',
      minWidth: 250,
      '&:hover': {
        backgroundColor: '#ffd616',
      },
    },
  },
  requiredField: {
    color: '#f00',
    fontSize: 20,
  },
  requiredFieldText: {
    color: '#000',
    fontSize: 12,
  },
  popup: {
    // position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(19, 21, 23, 0.40)',
    backdropFilter: 'blur(6px)',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },
  withInfoLabel: {
    display: 'flex',
  },
  radioBtns: {
    display: 'flex',
    flexDirection: 'column !important',
    width: '100%',
    gap: 5,
    marginBottom: '8px !important',
  },
  labelFirst: {
    minWidth: 150,
    color: '#131517 !important',
    fontWeight: '600 !important',
    fontFamily: 'Averta bold !important',
    fontSize: '16px !important',
  },
  inputTextArea: {
    width: '96%',
    borderRadius: 8,
    border: '1px solid #E1E4EA',
    padding: 10,
    fontFamily: 'Averta Light',
    '& textarea': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
  },
  radioMovment: {
    '& label': {
      display: 'flex',
      padding: 2,
      gap: 6,
      '& span': {
        '&:first-child': {
          display: 'flex',
          alignItems: 'center',
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: 15,
          '&:first-child': {
            color: '#131517 !important',
            fontSize: 15,
          },
        },
        '&:nth-child(2)': {
          '&:first-child': {
            '&:first-child': {
              color: '#131517 !important',
            },
          },
        },
      },
    },
    // '& input': {
    //   marginLeft: 10,
    // },
  },
  cardP: {
    width: '675px',
    position: 'relative',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 16,
    border: '1px solid #E8E8E8',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '83%',
    overflow: 'auto',
  },
  loading: {
    height: '100%',
    margin: 0,
  },
  closeP: {
    position: 'absolute',
    top: 26,
    right: 24,
    height: 14,
    cursor: 'pointer',
    fill: '#000000',
  },
  tableTopActions: {
    display: 'flex',
    padding: '0px 0px 10px',
    gap: 10,
    borderBottom: '1px solid #E1E4EA',
    marginBottom: 5,
    fontSize: '20px',
    fontWeight: 900,
  },
  formSection: {
    marginTop: 10,
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 8,
    border: '1px solid #E1E4EA',
    padding: 5,
    width: '98%',
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '90px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    // border: '1px solid #E1E4EA',
    '& >:first-child': {
      width: '100%',
    },
    '& input': {
      fontFamily: 'Averta Light',
      padding: '10px 10px',
    },
  },

  msgPopupBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitBtn: {
    color: '#000',
    background: '#FFAD00',
    borderRadius: 8,
    minWidth: 88,
    padding: '8px 15px',
    marginTop: '10px',
    margin: '2px',
    border: '1px solid #FFAD00',
    width: 'max-content',
    lineHeight: '1.3rem',
    '& >span>div': {
      width: '21px !important',
      height: '21px !important',
    },
    '& svg': {
      width: '21px',
    },
  },
  cancelBtn: {
    color: '#000',
    background: 'transparent',
    borderRadius: 8,
    padding: '6px 20px',
    border: '1px solid #000',
    marginTop: '10px',
    margin: '2px',
    width: 'max-content',
    lineHeight: '1.3rem',
  },

  but: {
    width: 150,
    height: 38,
    borderRadius: 5,
    backgroundColor: '#007fff',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
    marginLeft: 'auto',
    marginTop: 20,
    '&:hover': {
      backgroundColor: '#007fff',
    },
  },
  dicsounticon: {
    width: '20px',
    height: '20px',
  },
  formClarify: {
    margin: '16px 0',
  },
  boldLable: {
    fontWeight: 500,
    marginRight: 5,
  },
}));
